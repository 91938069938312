<script lang="ts">
import OFilterDropdown from 'o365.vue.components.FilterDropdown.vue';

interface GroupByItem {
    colId: string,
    field?: string,
    filterItem?: any,
    placeholder: boolean,
    duplicate: boolean,
};

export default {
    components: { OFilterDropdown }
};
</script>

<script setup lang="ts">
import type DataGridControl from 'o365.controls.DataGrid.ts';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import { ref, computed, inject, watch, nextTick } from 'vue';

const dataGridControl = inject<DataGridControl>(dataGridControlKey, null)

// const configModalRef = ref(null);
const placeholders = ref<GroupByItem[]>([]);

const colIdsInGroupBy = new Set();
const groupBy = computed(() => {
    colIdsInGroupBy.clear();
    const arr = dataGridControl.value.groupBy.groupBy?.map(group => {
        const field = dataGridControl?.value.groupBy.getFieldFromColId(group);
        let isDuplicate = false;
        if (colIdsInGroupBy.has(group)) {
            isDuplicate = true;
        } else {
            colIdsInGroupBy.add(group);    
        }
        return {
            colId: group,
            field: field,
            filterItem: getFilterItem(field),
            placeholder: false,
            duplicate: isDuplicate,
        };
    }) ?? [];
    return arr.concat(placeholders.value);
})

const columnsMap = computed(() => {
    const result = {};
    dataGridControl.value.dataColumns.columns.forEach(column => {
        result[column.colId] = column;
    });
    return result;
});

function addGroupBy(colId) {
    dataGridControl.value.groupBy.addGroup(colId);
    //dataGridControl.value.dataColumns.getColumn(colId)._hide = true;
    dataGridControl.value.dataObject.load();
}

function removeGroupBy(level) {
    const colId = groupBy.value[level].colId;
    dataGridControl.value.groupBy.removeGroup(level);
    //dataGridControl.value.dataColumns.getColumn(colId)._hide = false;
    dataGridControl.value.dataObject.load();
}

//--- DRAG AND DROP ---
let placeholderDebounce: number|null = null;

function handleDrop(e) {
    if (e.dataTransfer?.types?.includes('o365-nt/group-by-order')) {
        handleOrderDrop(e);
        return;
    };
    const colId = e.dataTransfer.getData('text');
    if (!colId || colId === 'o365_Group') { return; }
    // if (fieldIsAggregated(colId)) { return;}
    
    dataGridControl.value.columnMove.cancelColumnMove(true);
    e.stopPropagation();

    if (placeholderDebounce) { clearTimeout(placeholderDebounce); }
    placeholders.value = [];
    addGroupBy(colId);
}

function handleDragEnter(e) {
    const colId = dataGridControl.value?.columnMove?.movedColumn?.field;
    if (!colId || colId === 'o365_Group') { return; }
    // if (fieldIsAggregated(colId)) { return; }

    if (placeholderDebounce) { clearTimeout(placeholderDebounce); }
    placeholderDebounce = setTimeout(() => {
        let isDuplicate = false;
        if (colIdsInGroupBy.has(colId)) {
            isDuplicate = true;
        }
        placeholders.value = [{
            colId: colId,
            placeholder: true,
            duplicate: isDuplicate,
            //column: getColumn(field),
        }];
    }, 150);
} 

function handleDragLeave (e) {
    if (placeholderDebounce) { clearTimeout(placeholderDebounce); }
    if (!clearPlaceholders) {
        placeholders.value = [];
    }
}

let clearPlaceholders: number|null = null;
function handleDragOver(e) {
    if (placeholders.value.length > 0) {
        if (clearPlaceholders) { clearTimeout(clearPlaceholders); }
        clearPlaceholders = setTimeout(() => {
            placeholders.value = [];
        }, 100);
    }
}

function fieldIsAggregated(colId) {
    return !!dataGridControl.value.groupBy.groupByAggregates[colId];
}

///--- Filter ---


function getFilterItem(field) {
    return dataGridControl.value.dataObject.filterObject?.getItem(field);
}

const popperOptions = [{
    name: 'offset',
    options: {
        offset: [-1, -1],
    },
}];

//--- Sorting ---

function handleSort(e, column) {
    if (!column || !column.sortable) { return; }
    const dataObject = dataGridControl.value.dataObject;
    e.preventDefault();

    const currentField = dataObject.getFields(column.name);
    let vCurrentSortOrder = dataObject.recordSource.getSortOrder();
    let vSortOrder = {};
    if (!vCurrentSortOrder) vCurrentSortOrder = [];

    switch (currentField.sortDirection) {
        case 'asc':
            currentField.sortDirection = 'desc';
            break;
        case 'desc':
        default:
            currentField.sortDirection = 'asc';
    }

    vSortOrder[column.field] = currentField.sortDirection;
    if (!e.ctrlKey) {
        vCurrentSortOrder = [vSortOrder];
    } else {
        if (column.sort) {
            vCurrentSortOrder.forEach((item, index) => {
                const vTmp = Object.keys(item);
                if (vTmp[0] === column.field) {
                    vCurrentSortOrder[index] = vSortOrder;
                }
            });
        } else {
             vCurrentSortOrder.push(vSortOrder);
        }
    }

    dataObject.recordSource.setSortOrder(vCurrentSortOrder);

    const columns = dataGridControl.value.dataColumns.columns;
    columns.filter(x => x.sort).forEach(col => {
        col.sortOrder = null;
        col.sort = null;
    });

    vCurrentSortOrder.forEach((ord, index) => {
        const vTmp = Object.keys(ord);
        const vCol = columns.find(x => x.field === vTmp[0]);
        if (vCol) {
            if (vCurrentSortOrder.length > 1) {
                vCol.sortOrder = index + 1;
            }
            vCol.sort = ord[vTmp[0]];
        }
    })

    /*
    if (dataGridControl.value.dataObject.layoutManager) {
            dataGridControl.value.dataObject.layoutManager.trackSortByChange();
    }
    */

    dataObject.load();
}

//--- Configuration Modal ---

const selectableColumnsForAggregate = computed(() => {
    const checkMap = dataGridControl.value.dataObject.groupBy.groupBy.reduce((obj, level) => {
        level.forEach(group => {
            obj[group.name] = true;
        });
        return obj;
    }, {});
    return dataGridControl.value.dataColumns.columns.filter(x => !x.unbound && !checkMap[x.field]);
});

const aggregateColumns = computed(() => {
    return dataGridControl.value.dataObject.groupBy.groupBy[0].filter(x => x.groupByAggregate !== undefined);
});

function setFieldAggregate(e, name) {
    const value = e.target.value;
    if (!value) {
        const groupIndex = dataGridControl.value.dataObject.groupBy.groupBy[0].findIndex(x=>x.name === name);
        dataGridControl.value.dataObject.groupBy.groupBy[0].splice(groupIndex, 1);
    } else {
        const group = dataGridControl.value.dataObject.groupBy.groupBy[0].find(x=>x.name === name);
        group.groupByAggregate = value;
    }
}

const cfgSelectedField = ref(null);
const cfgSelectedAggregate = ref(null);

function addFieldToAggregates() {
    if (!cfgSelectedField.value || !cfgSelectedAggregate.value ) { return; }

    dataGridControl.value.dataObject.groupBy.addGroupToLevel({name: cfgSelectedField.value, groupByAggregate: cfgSelectedAggregate.value}, 0);
    window.requestAnimationFrame(() => {
        cfgSelectedAggregate.value = null;
        cfgSelectedField.value = null;
    });
}

function handleMaxRecordModeChange(e, group) {
    if (e.target.checked) {
        dataGridControl.value.groupBy.groupsMaxReords[group.field].mode = 'auto';
    } else {
        dataGridControl.value.groupBy.groupsMaxReords[group.field].mode = 'number';
    }
}

//--- Order drag events ---

let sortingGroupItem = null;
function handleOrderDragStart(e) {
    sortingGroupItem = null;
    const groupByElement = e.target.closest('.o365-group-by-item');
    
    if (!groupByElement) { return; }
    const dataTransfer = e.dataTransfer;
    dataTransfer.setDragImage(groupByElement, 16, 8);
    dataTransfer.effectAllowed = 'move'

    const colId = groupByElement.getAttribute('o365-group-by-item');
    const groupIndex = groupBy.value.findIndex(x => x.colId === colId);
    sortingGroupItem = { name: colId, index: groupIndex };
    dataTransfer.setData('o365-nt/group-by-order', JSON.stringify(sortingGroupItem))
}

function handleOrderDrop(e) {
    const transferedString = e.dataTransfer.getData('o365-nt/group-by-order');
    const sortingItem = JSON.parse(transferedString);
    const dropOnItem = e.target.closest('.o365-group-by-item');
    if (dropOnItem && dropOnItem.getAttribute('o365-group-by-item')) {
        const dropOnField = dropOnItem.getAttribute('o365-group-by-item');
        if (dropOnField === sortingItem.name) { return; }
        const dropOnIndex = dataGridControl.value.groupBy.groupBy.findIndex(x => x === dropOnField);
        const groupByArr = [...dataGridControl.value.groupBy.groupBy];

        groupByArr.splice(sortingItem.index, 1);
        groupByArr.splice(dropOnIndex, 0, sortingItem.name);
        dataGridControl.value.groupBy.setGroupBy(groupByArr);
        dataGridControl.value.dataObject.load();
    }
};

/*
let sortableInstance = null;
const sortableContainerRef = ref(null);
watch(() => groupBy.value.length, (newValue) => {
    if (newValue > 0 && !sortableInstance) {
        nextTick().then(() => {
            sortableInstance = new Sortable(sortableContainerRef.value, {
                draggable: '.o365-group-by-item',
                handle: '.o365-group-by-item-handle',
                filter: '.o365-group-by-seperator',
                swapThreshold: 0.5,
                onUpdate: (e) => {
                    console.log(e.item);
                    if (e.newIndex > e.oldIndex) {
                        e.item.parentNode.insertBefore(e.item, e.item.previousSibling.previousSibling);
                    } else {
                        e.item.parentNode.insertBefore(e.item, e.item.nextSibling.nextSibling.nextSibling);
                    }
                }
            });
        });
    } else if (newValue === 0 && sortableInstance) {
        sortableInstance = null;
    }
});
*/
</script>

<template>
    <div class="w-100 column-group-by py-2 d-flex align-items-center" 
        @drop="handleDrop" @dragenter="handleDragEnter"
        @dragover="handleDragOver" @dragleave="handleDragLeave">
        <i class="bi bi-box-arrow-in-up-left mx-2"></i>
        <template v-if="groupBy.length === 0">
            <span>{{$t('Drag columns here to set group by')}}</span>
        </template>
        <template v-else>
            
            <!--
            <button class="btn btn-primary btn-sm" :title="$t('Configure Group By')" @click="configModalRef.show">
                <i class="bi bi-wrench-adjustable"></i>
            </button>
            <OModal ref="configModalRef" :title="$t('Group By Configuration')" dialog-class="modal-xl">
                <div class="modal-body">
                    <template v-if="selectableColumnsForAggregate.length > 0">
                        <div class="row">
                            <div v-for="(group, groupLevel) in groupBy" :key="groupLevel" class="col-4">
                                <template v-if="!group.placeholder">
                                    <div class="row">
                                        <div class="col-5">
                                            <b>{{columnsMap[group.field].headerName}}</b>
                                        </div>
                                        <div class="col-7">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" :id="group.field+'-maxRecordsSwitch'" 
                                                    @change="handleMaxRecordModeChange($event, group)"
                                                    :checked="dataGridControl.groupBy.groupsMaxReords[group.field].mode === 'auto'">
                                                <label class="form-check-label" :for="group.field+'-maxRecordsSwitch'">{{$t('Auto set loading block size')}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <input v-if="dataGridControl.groupBy.groupsMaxReords[group.field].mode === 'number'" 
                                        v-model="dataGridControl.groupBy.groupsMaxReords[group.field].maxRecords" 
                                        class="form-control form-control-sm" type="number">
                                </template>
                            </div>
                        </div>
                        <hr>
                        <h6>{{$t('Aggregate field')}}</h6>
                        <div class="row">
                            <div class="col-4">
                                <label :for="dataGridControl.id+'-'+'-addfield'" class="form-label">Select field</label>
                                <select class="form-select" :id="dataGridControl.id+'-'+'-addfield'" v-model="cfgSelectedField">
                                    <option v-for="column in selectableColumnsForAggregate" :key="column.colId" :value="column.colId">{{column.headerName}}</option>
                                </select>
                            </div>
                            <div class="col-4" v-if="cfgSelectedField">
                                <label :for="dataGridControl.id+'-'+'-aggregate-addfield'" class="form-label">Select aggregate</label>
                                <select class="form-select" :id="dataGridControl.id+'-'+'-aggregate-addfield'" v-model="cfgSelectedAggregate">
                                    <template v-if="columnsMap[cfgSelectedField].type === 'date' || columnsMap[cfgSelectedField].type === 'datetime'">
                                        <option value="COUNT">{{$t('COUNT')}}</option>
                                        <option value="MIN">{{$t('MIN')}}</option>
                                        <option value="MAX">{{$t('MAX')}}</option>
                                    </template>
                                    <template v-else-if="columnsMap[cfgSelectedField].type !== 'number'">
                                        <option value="COUNT">{{$t('COUNT')}}</option>
                                    </template>
                                    <template v-else>
                                        <option value="COUNT">{{$t('COUNT')}}</option>
                                        <option value="SUM">{{$t('SUM')}}</option>
                                        <option value="AVG">{{$t('AVG')}}</option>
                                        <option value="MIN">{{$t('MIN')}}</option>
                                        <option value="MAX">{{$t('MAX')}}</option>
                                    </template>
                                </select>
                            </div>
                            <div class="col-4 d-flex align-items-end" v-if="cfgSelectedAggregate">
                                <button class="btn btn-primary" @click="addFieldToAggregates">{{$t('Add aggregate')}}</button>
                            </div>
                        </div>
                        <hr v-if="aggregateColumns.length > 0">
                    </template>
                    <div class="row">
                        <div v-for="group in aggregateColumns" :key="group.name" class="col-6 col-lg-3">
                            <div class="form-floating w-100 mt-2">
                                <select class="form-select" :id="dataGridControl.id+'-'+group.name+'-aggregate'" :value="group.groupByAggregate" @change="setFieldAggregate($event, group.name)">
                                    <template v-if="columnsMap[group.name].type !== 'date' || columnsMap[group.name].type !== 'datetime'">
                                        <option value="COUNT">{{$t('COUNT')}}</option>
                                        <option value="MIN">{{$t('MIN')}}</option>
                                        <option value="MAX">{{$t('MAX')}}</option>
                                    </template>
                                    <template v-else-if="columnsMap[group.name].type !== 'number'">
                                        <option></option>
                                        <option value="COUNT">{{$t('COUNT')}}</option>
                                    </template>
                                    <template v-else>
                                        <option></option>
                                        <option value="COUNT">{{$t('COUNT')}}</option>
                                        <option value="SUM">{{$t('SUM')}}</option>
                                        <option value="AVG">{{$t('AVG')}}</option>
                                        <option value="MIN">{{$t('MIN')}}</option>
                                        <option value="MAX">{{$t('MAX')}}</option>
                                    </template>
                                </select>
                                <label :for="dataGridControl.id+'-'+group.name+'-aggregate'">{{columnsMap[group.name].headerName}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="() => {dataGridControl.dataObject.load(); configModalRef.hide();}"
                        class="btn btn-outline-primary">{{$t('Load')}}</button>
                </div>
            </OModal>

            -->
            <div class="d-flex align-items-center ms-2" ref="sortableContainerRef">
                <template v-for="(groupLevel, levelIndex) in groupBy" :key="levelIndex">
                    <div class="d-flex align-items-center rounded o365-group-by-item"
                        :class="{'opacity-50': groupLevel.placeholder, 'text-bg-primary': !groupLevel.duplicate, 'text-bg-danger': groupLevel.duplicate}" :o365-group-by-item="groupLevel.colId">
                        <span class="mx-2">
                            <i class="bi bi-grip-vertical p-2" style="cursor: grab" draggable="true" @dragstart="handleOrderDragStart"></i>
                            <!-- <i class="bi bi-grip-vertical p-2 o365-group-by-item-handle" style="cursor: grab"></i> -->
                            {{columnsMap[groupLevel.colId].headerName}}
                        </span>
                        <button v-if="columnsMap[groupLevel.colId].sortable" type="button" class=" btn btn-sm" :class="groupLevel.duplicate ? 'btn-danger' : 'btn-primary'" @click="handleSort($event, columnsMap[groupLevel.colId])">
                            <i v-if="!columnsMap[groupLevel.colId].sort" class="bi bi-arrow-down-up"></i>
                            <i v-else-if="columnsMap[groupLevel.colId].sort === 'asc'" class="bi bi-arrow-up"></i>
                            <i v-else-if="columnsMap[groupLevel.colId].sort === 'desc'" class="bi bi-arrow-down"></i>
                            <small v-if="columnsMap[groupLevel.colId].sortOrder">{{columnsMap[groupLevel.colId].sortOrder}}</small>
                        </button>
                        <ODropdown v-if="groupLevel.filterItem" placement="bottom-end" :popperOptions="popperOptions">
                            <template #default="scope">
                                <button type="button" class="btn btn-sm" :class="groupLevel.duplicate ? 'btn-danger' : 'btn-primary'" :ref="scope.target"
                                    @click="scope.open()">
                                    <i class="bi bi-funnel-fill"></i>
                                </button>
                            </template>
                            <template #dropdown="scope">
                                <div class="card shadow rounded-0 o365-filter-dropdown bg-light-subtle" :ref="scope.container">
                                    <div class="p-2" style="width: 400px">
                                        <component :is="groupLevel.filterItem.filterDropdown"
                                            :filterItem="groupLevel.filterItem"
                                            :filterObject="dataGridControl.dataObject.filterObject"
                                            :dropdown="scope">
                                        </component>
                                    </div>
                                </div>
                            </template>
                        </ODropdown>
                        <button type="button" class=" btn btn-sm" :class="groupLevel.duplicate ? 'btn-danger' : 'btn-primary'" @click="columnsMap[groupLevel.colId].hide = !columnsMap[groupLevel.colId].hide"
                            :title="$t(columnsMap[groupLevel.colId]._hide ? 'Show column' : 'Hide column')">
                            <i v-if="columnsMap[groupLevel.colId]._hide" class="bi bi-eye-fill"></i>
                            <i v-else class="bi bi-eye-slash-fill"></i>
                        </button>
                        <button type="button" class="btn btn-sm rounded" :class="groupLevel.duplicate ? 'btn-danger' : 'btn-primary'" @click="removeGroupBy(levelIndex)">
                            <i class="bi bi-x-lg"></i>
                        </button>
                    </div>

                    <i v-if="levelIndex < groupBy.length-1"  class="bi bi-chevron-double-right mx-2 o365-group-by-seperator"></i>

                </template>
            </div>

        </template>
    </div>
</template>